import { UserDataInterfaceWithId } from '../interfaces/userData.interface';
import { LeaderboardInterface } from '../interfaces/offers.interface';

export function convertSnaps<T>(results: any) {
    return <T[]> results.docs.map((snap: any) => {
        return {
            id: snap.id,
            exists: snap.exists,
            ...<any>snap.data()
        }
    })
}

export function sortByKey<T>(results: any[], key: string) {
    return <T[]> results.sort((a, b) => {
        const nameA = a?.[key].toUpperCase(); // ignore upper and lowercase
        const nameB = b?.[key].toUpperCase(); // ignore upper and lowercase
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
      
        // names must be equal
        return 0;
    });
}

export function getName(
  user: UserDataInterfaceWithId | LeaderboardInterface,
  format: 'fullName' | 'firstName' | 'lastName' = 'fullName',
): string {
  if (format === 'fullName') {
    if (user?.firstName && user?.lastName) {
      return `${user.firstName} ${user.lastName}`;
    } else if (user?.firstName) {
      return user.firstName;
    } else if (user?.lastName) {
      return user.lastName;
    }
  } else if (format === 'firstName' && user?.firstName) {
    return user.firstName;
  } else if (format === 'lastName' && user?.lastName) {
    return user.lastName;
  }

  return user?.id ? user.id : '-';
}
