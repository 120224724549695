import { EnvironmentInterface } from '../app/_shared/interfaces/environment.interface';

export const environment: EnvironmentInterface = {
  production: true,
  firebase: {
    apiKey: "AIzaSyDJS6sWWrsPUUEqEtrgEfSLYzcnfBuiYTY",
    authDomain: "mycellar-prod.firebaseapp.com",
    databaseURL: "https://mycellar-prod-default-rtdb.firebaseio.com",
    projectId: "mycellar-prod",
    storageBucket: "mycellar-prod.appspot.com",
    messagingSenderId: "902913441328",
    appId: "1:902913441328:web:169a32dce1285003ffb7f5",
    measurementId: "G-TW5ZJJRGDR"
  },
  appName: 'Cellar',
  apiUrl: 'https://us-central1-mycellar-prod.cloudfunctions.net',
  apiPosUrl: 'https://us-central1-mycellar-prod.cloudfunctions.net',
  mapsApiKey: 'AIzaSyBdr7hxokuupUflBsCW50gzgJjPy4uQadU',
  baseUrl: 'https://cellar-cms.web.app',
  release: '2.2.92',
  envName: 'PROD',
  hoodieAPIkey: '64b0137687c0842a46a7a150ddafb443',
  searchAPIkey: 'd673dc9651f37064602675b471404a43',
  makioUrl: 'https://makio-prod.leafvip.app',
  appBrandId: 'zgyVwUzX6hjvoLWNgUir',
};
